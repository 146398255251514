:root {
  --primary-color: #4461d4;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@layer base {
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    src: url(./assets/fonts/FiraSans-100.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-100-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    src: url(./assets/fonts/FiraSans-200.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-200-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    src: url(./assets/fonts/FiraSans-300.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-300-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    src: url(./assets/fonts/FiraSans-400.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-400-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    src: url(./assets/fonts/FiraSans-500.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-500-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    src: url(./assets/fonts/FiraSans-600.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-600-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    src: url(./assets/fonts/FiraSans-700.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-700-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    src: url(./assets/fonts/FiraSans-800.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-800-italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    src: url(./assets/fonts/FiraSans-900.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    font-style: italic;
    src: url(./assets/fonts/FiraSans-900-italic.ttf) format('truetype');
  }
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--primary-color);
  --amplify-components-button-primary-hover-background-color: rgba(
    68,
    97,
    212,
    0.7
  );
  --amplify-components-button-link-color: var(--primary-color);
  --amplify-components-button-link-hover-background-color: #f5f7fa;
}

body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
